<script setup lang="ts">
import CookiesAgreementBanner from '~/components/layout/CookiesAgreementBanner.vue';
import BackendError from '~/components/layout/errors/BackendError.vue';

provide('theme', 'dark');

const router = useRouter();
const localePath = useLocalePath();
const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);

function handleLoggedInState() {
  if (!isLoggedIn.value) {
    return;
  }

  router.push(localePath('dashboard'));
}

onMounted(() => {
  document.body.classList.remove('light');
  document.body.classList.add('dark');

  handleLoggedInState();
});
</script>

<template>
  <ClientOnly>
    <BackendError />

    <main id="main">
      <slot />
    </main>

    <CookiesAgreementBanner />
  </ClientOnly>
</template>

<style lang="scss" scoped>
main {
  display: grid;
  grid-template-columns: 1fr;

  min-height: 100%;
  height: 100vh;

  @media screen and (min-width: 768px) {
    grid-template-columns: 60% 40%;
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: 45% 55%;
  }
}
</style>
